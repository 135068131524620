import { Locale, type LocalizedString } from "../Locale.js";
import type { Tenant } from "../Tenant.js";
import { getPathInLocale } from "./getPathInLocale.js";

export function getProjectRescuePath(tenant: Tenant): string {
	const pathMap: LocalizedString = {
		[Locale.cs]: "/it-sluzby/zachrana-projektu",
		[Locale.en]: "/it-services/project-rescue",
	};

	return getPathInLocale(tenant.locale, pathMap);
}
